<template>
  <!-- Header start -->
  <header class="mb-3">
    <a href="#" class="burger-btn d-block d-xl-none">
      <i class="bi bi-justify fs-3"></i>
    </a>
  </header>
  <!--/ Header end -->
</template>

<script>
export default {
  name: 'Header',
  data () {
    return {
      baseURL: process.env.VUE_APP_BASE_URL
    }
  },
  methods: {
    handleSession: function () {
      if (this.$store.state.loggedInState) {
        this.$store.dispatch('logout')
      }
      this.$router.push({ name: 'login' })
    }
  }
}
</script>
