<template>
  <Sidebar></Sidebar>
  <div id="main">
    <Header></Header>
    <router-view />
    <Footer></Footer>
  </div>
</template>
<script>
// @ is an alias to /src
import Header from './Header.vue'
import Footer from './Footer'
import Sidebar from './Sidebar'

export default {
  name: 'AppLayout',
  components: {
    Header,
    Sidebar,
    Footer
  },
  mounted () {
    this.loadScripts()
  },
  methods: {
    loadScripts: function () {
      // eslint-disable-next-line no-undef
      setTimeout($GLOBAL.MazerJS(), 5000)
    }
  }
}
</script>
