<template>
  <div id="sidebar" class="active">
    <div class="sidebar-wrapper active">
      <div class="sidebar-header">
        <div class="d-flex justify-content-between">
          <div class="logo">
            <router-link :to="{name:'home'}"><img src="assets/admin-master-template/admin-assets/assets/images/logo/logo.png" alt="Logo" srcset=""></router-link>
          </div>
          <div class="toggler">
            <a href="javascript:void(0);" class="sidebar-hide d-xl-none d-block"><i class="bi bi-x bi-middle"></i></a>
          </div>
        </div>
      </div>
      <div class="sidebar-menu">
        <ul class="menu">
          <router-link :to="{ name: 'admin-product' }">
            <li
              class="sidebar-item" :class="$route.name === 'admin-product' ? 'active' : ''">
              <a href="javascript:void(0);" class='sidebar-link'>
                <i class="bi bi-box"></i>
                <span class="ml-3" style="color: #25396f">Products</span>
              </a>
            </li>
          </router-link>
          <router-link :to="{ name: 'admin-property' }">
            <li
              class="sidebar-item" :class="$route.name === 'admin-property' ? 'active' : ''">
              <a href="javascript:void(0);" class='sidebar-link'>
                <i class="bi bi-card-checklist"></i>
                <span class="ml-3" style="color: #25396f">Properties</span>
              </a>
            </li>
          </router-link>
          <!--
          <li
            class="sidebar-item" :class="$route.name === 'admin-specification' ? 'active' : ''">
            <a href="javascript:void(0);" class='sidebar-link'>
              <i class="bi bi-bounding-box"></i>
              <router-link :to="{ name: 'admin-specification' }"><span class="ml-3" style="color: #25396f">Specifications</span></router-link>
            </a>
          </li>
          -->
          <router-link :to="{ name: 'admin-file' }">
            <li
              class="sidebar-item " :class="$route.name === 'admin-file' ? 'active' : ''">
              <a href="javascript:void(0);" class='sidebar-link'>
                <i class="bi bi-layers"></i>
                <span class="ml-3" style="color: #25396f">Files</span>
              </a>
            </li>
          </router-link>
          <router-link :to="{ name: 'admin-business' }">
            <li
              class="sidebar-item" :class="$route.name === 'admin-business' ? 'active' : ''">
              <a href="javascript:void(0);" class='sidebar-link'>
                <i class="bi bi-briefcase"></i>
                <span class="ml-3" style="color: #25396f">Businesses</span>
              </a>
            </li>
          </router-link>
          <router-link :to="{ name: 'admin-offer' }">
            <li
              class="sidebar-item" :class="$route.name === 'admin-offer' ? 'active' : ''">
              <a href="javascript:void(0);" class='sidebar-link'>
                <i class="bi bi-gift"></i>
                <span class="ml-3" style="color: #25396f">Offers</span>
              </a>
            </li>
          </router-link>
          <router-link :to="{ name: 'admin-news' }">
            <li
              class="sidebar-item" :class="$route.name === 'admin-news' ? 'active' : ''">
              <a href="javascript:void(0);" class='sidebar-link'>
                <i class="bi bi-newspaper"></i>
                <span class="ml-3" style="color: #25396f">News</span>
              </a>
            </li>
          </router-link>
          <router-link :to="{ name: 'admin-service' }">
            <li
              class="sidebar-item" :class="$route.name === 'admin-service' ? 'active' : ''">
              <a href="javascript:void(0);" class='sidebar-link'>
                <i class="bi bi-arrows-move"></i>
                <span class="ml-3" style="color: #25396f">Services</span>
              </a>
            </li>
          </router-link>
          <router-link :to="{ name: 'admin-review' }">
            <li
              class="sidebar-item" :class="$route.name === 'admin-review' ? 'active' : ''">
              <a href="javascript:void(0);" class='sidebar-link'>
                <i class="bi bi-star"></i>
                <span class="ml-3" style="color: #25396f">Reviews</span>
              </a>
            </li>
          </router-link>
          <router-link :to="{ name: 'admin-ads' }">
            <li
              class="sidebar-item" :class="$route.name === 'admin-ads' ? 'active' : ''">
              <a href="javascript:void(0);" class='sidebar-link'>
                <i class="bi bi-badge-ad"></i>
                <span class="ml-3" style="color: #25396f">Ads</span>
              </a>
            </li>
          </router-link>
          <router-link :to="{ name: 'admin-adsSettings' }">
            <li
              class="sidebar-item" :class="$route.name === 'admin-adsSettings' ? 'active' : ''">
              <a href="javascript:void(0);" class='sidebar-link'>
                <i class="bi bi-file-earmark-image"></i>
                <span class="ml-3" style="color: #25396f">Website Ads</span>
              </a>
            </li>
          </router-link>
          <router-link :to="{ name: 'admin-brochure' }">
            <li
              class="sidebar-item" :class="$route.name === 'admin-brochure' ? 'active' : ''">
              <a href="javascript:void(0);" class='sidebar-link'>
                <i class="bi bi-file-earmark"></i>
                <span class="ml-3" style="color: #25396f">Product Brochure</span>
              </a>
            </li>
          </router-link>
          <router-link :to="{ name: 'admin-inquiry' }">
            <li
              class="sidebar-item" :class="$route.name === 'admin-inquiry' ? 'active' : ''">
              <a href="javascript:void(0);" class='sidebar-link'>
                <i class="bi bi-chat-left-text"></i>
                <span class="ml-3" style="color: #25396f">Inquiry List</span>
              </a>
            </li>
          </router-link>
          <router-link :to="{ name: 'admin-slider' }">
            <li
              class="sidebar-item" :class="$route.name === 'admin-slider' ? 'active' : ''">
              <a href="javascript:void(0);" class='sidebar-link'>
                <i class="bi bi-images"></i>
                <span class="ml-3" style="color: #25396f">Slider</span>
              </a>
            </li>
          </router-link>
          <router-link :to="{ name: 'admin-video' }">
            <li
              class="sidebar-item" :class="$route.name === 'admin-video' ? 'active' : ''">
              <a href="javascript:void(0);" class='sidebar-link'>
                <i class="bi bi-camera-reels  "></i>
                <span class="ml-3" style="color: #25396f">Video</span>
              </a>
            </li>
          </router-link>
          <li
            class="sidebar-item  has-sub" :class="$route.name === 'admin-brand' || $route.name === 'admin-vehicleType' || $route.name === 'admin-vehicleCategory' || $route.name === 'admin-vehicleSubCategory' ? 'active' : ''">
            <a href="javascript:void(0);" class='sidebar-link'>
              <i class="bi bi-slash-circle"></i>
              <span>General Inputs</span>
            </a>
            <ul class="submenu ">
              <li class="submenu-item " :class="$route.name === 'admin-brand' ? 'active' : ''">
                <router-link :to="{ name: 'admin-brand' }">Brand</router-link>
              </li>
              <li class="submenu-item " :class="$route.name === 'admin-vehicleType' ? 'active' : ''">
                <router-link :to="{ name: 'admin-vehicleType' }">Vehicle Type</router-link>
              </li>
              <li class="submenu-item " :class="$route.name === 'admin-vehicleCategory' ? 'active' : ''">
                <router-link :to="{ name: 'admin-vehicleCategory' }">Vehicle Category</router-link>
              </li>
              <li class="submenu-item " :class="$route.name === 'admin-vehicleSubCategory' ? 'active' : ''">
                <router-link :to="{ name: 'admin-vehicleSubCategory' }">Vehicle Subcategory</router-link>
              </li>
              <li class="submenu-item " :class="$route.name === 'admin-aboutUs' ? 'active' : ''">
                <router-link :to="{ name: 'admin-aboutUs' }">About Us</router-link>
              </li>
              <li class="submenu-item " :class="$route.name === 'admin-faq' ? 'active' : ''">
                <router-link :to="{ name: 'admin-faq' }">FAQ</router-link>
              </li>
              <li class="submenu-item " :class="$route.name === 'admin-termsAndConditions' ? 'active' : ''">
                <router-link :to="{ name: 'admin-termsAndConditions' }">Terms and Conditions</router-link>
              </li>
              <li class="submenu-item " :class="$route.name === 'admin-othersMenu' ? 'active' : ''">
                <router-link :to="{ name: 'admin-othersMenu' }">Others Menu</router-link>
              </li>
            </ul>
          </li>
          <router-link :to="{ name: 'admin-settings' }">
            <li
              class="sidebar-item" :class="$route.name === 'admin-settings' ? 'active' : ''">
              <a href="#" class='sidebar-link'>
                <i class="bi bi-gear"></i>
                <span class="ml-3" style="color: #25396f">Settings</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
      <button class="sidebar-toggler btn x"><i data-feather="x"></i></button>
    </div>
  </div>
</template>
<style>
.sidebar-wrapper .menu .sidebar-item.active>.sidebar-link span{color:#fff !important;}
.sidebar-wrapper .menu .sidebar-link{font-size:1.05rem !important;}
.bi {
  height: 1.1rem;
  width: 1.1rem;
}
</style>
<script>
export default {
  name: 'Sidebar',
  data () {
    return {
      baseURL: process.env.VUE_APP_BASE_URL
    }
  }
}
</script>

<style scoped>
  .sidebar-wrapper .menu .sidebar-item.active>.sidebar-link>a> span{
    color: #ffffff !important;
  }
</style>
