<template>
  <footer>
    <div class="footer clearfix mb-0 text-muted">
      <div class="float-start">
        <p>{{ new Date().getFullYear() }} &copy; Garirzone</p>
      </div>
      <div class="float-end">
        <p>All rights reserved to <a href="http://garirzone.chittagongpower.com" target="_blank">Garirzone</a></p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  data () {
    return {
      baseURL: process.env.VUE_APP_BASE_URL
    }
  }
}
</script>
